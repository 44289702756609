import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import PatternIframe from "../component/PatternIframe.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A dashboard is a tool that consists of screens that provide data visualization for specific information. Information comes in any form of data displaying actionable and relevant insights. Therefore, a dashboard should be easy-to-scan and contain the key information that users need.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Guideline pattern`}</strong>{`: In this pattern, various information from dashboards are presented based on their function and feature. Please be aware on every page, there are Do(s) & Don’t(s) that we should read carefully.`}</p>
    </InlineNotification>
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Information design & user experience</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Data Visualization</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Hierarchy & Layout</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Table & Data Filter</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Form for Submit Data</AnchorLink>
    </AnchorLinks>
    <div className="divi" />
    <h2>{`Information design & user experience`}</h2>
    <p>{`When designing a dashboard, we need to prioritize the following information of design goals in conjunction with the use of a grid. A challenge in creating and developing dashboards is that they too often obscure or otherwise fail to deliver the information they are intended to impart because they are poorly or at least not thoughtfully designed.`}</p>
    <h3>{`Information design goals`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/information-design-goals.svg",
      "alt": "information design goals"
    }}></img>
    <h3>{`User experience goals`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/user-experience-goals.svg",
      "alt": "information design goals"
    }}></img>
    <div className="divi" />
    <h2>{`Hierarchy Layout`}</h2>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/hierarchy-layout.svg",
      "alt": "information design goals"
    }}></img>
    <p>{`Users usually read from the top left corner, so we should put the important information from left to right. Create a continuous flow that is easy to scan across the dashboard by placing related information in a grouping that makes sense. On the other hand, don’t overwhelm users with too much information. We suggested using max 5-8 different card chart or information to create the dashboard. Personalization and customization will help users to see what information matters to them.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use consistent structure, making it easier for users to work with the data and heavy information can be grouped and divided by tabs." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/hierarchy-layout-do.svg",
          "alt": "Dashboard Pattern Do"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Display too much information on a card can create confusion for users and present too much data in the above-the-fold area of the dashboard." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/hierarchy-layout-dont.svg",
          "alt": "Dashboard Pattern Don't"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Structure the information or layout design based on 12 column grids for desktop." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/hierarchy-layout-grid-do.svg",
          "alt": "Dashboard Pattern Do"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Not using the grid makes the dashboard design not have a balanced layout." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/hierarchy-layout-grid-dont.svg",
          "alt": "Dashboard Pattern Don't"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Table and Data Filter`}</h2>
    <p>{`This pattern provides suggestions for the most used table in a dashboard. The table shows information and data in rows and columns from the database, such as product or customer lists. Because a table usually shows a lot of data and value, a filter will help users to go through the data and analyze it more easily.`}</p>
    <p><strong parentName="p">{`User Story`}</strong>{`: As a website user, I want to find and analyze data that shows from a table in a dashboard.`}</p>
    <p><strong parentName="p">{`Requirement Scenario/Acceptance Criteria`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Column alignment recommendation: right-aligned numeric columns, left-aligned text columns, and align the column of names and content.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide a call to action attribute for the table (filter, download, etc.).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Row-level actions, such as edit, delete, etc., can be presented directly at the row, but if more than three actions are needed, they can be provided through the kebab menu or hover.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`On heavy data, use pagination to reduce the user’s cognitive load (max. 20 rows per page).`}</p>
      </li>
    </ul>
    <br />
    <PatternIframe url="https://legion-pattern-dashboard-telkom-design.vercel.app/tables/advanced" breakpoints={["lg", "xl"]} mdxType="PatternIframe" />
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use filters outside the table, and those filters can apply multiple filters." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/table-and-data-filter-do-one.svg",
          "alt": "Dashboard Pattern Do"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use filters and sorting for data that don't need to be analyzed, such as history." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/table-and-data-filter-dont-one.svg",
          "alt": "Dashboard Pattern Don't"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="The column is best presented in 5-7 columns and select the data priority and input it in the filter if you need to serve a lot of data." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/table-and-data-filter-do-two.svg",
          "alt": "Dashboard Pattern Do"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Present too many columns make high cognitive load for user to scan or find the data inside the table." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/table-and-data-filter-dont-two.svg",
          "alt": "Dashboard Pattern Don't"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Form for Submit Data`}</h2>
    <p>{`This pattern will provide information on how to create a usable form for submitting data that consists of input fields, suggested layout, grouping related fields, and dividing the information in a way that is easy to follow for users to go through a lot of information.`}</p>
    <p><strong parentName="p">{`User Story`}</strong>{`: As a website user, I want to find a simple way to submit data to go through the form easily and quickly.`}</p>
    <p><strong parentName="p">{`Requirement Scenario/Acceptance Criteria`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Related fields can be grouped by proximity, similarity, continuity, and connectedness.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Avoid using multiple columns. Use a single column throughout the form.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Left-aligned labels have a twice faster completion time.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide input fields such as text, password, checkboxes, buttons, and other fields for user input.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If it requires many fields more than 5 grouping fields, break columns into simple steps (Wizard form).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Reduce the option to navigate outside the wizard form, and avoid putting links or other navigation that will disrupt the process and create confusion.`}</p>
      </li>
    </ul>
    <br />
    <PatternIframe url="https://legion-pattern-dashboard-telkom-design.vercel.app/forms/wizard" breakpoints={["lg", "xl"]} mdxType="PatternIframe" />
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Grouped forms by categories & combining fields can make users focussed and using wizard form to handle many form submissions." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/form-for-submit-data-do-one.svg",
          "alt": "Dashboard Pattern Do"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Displaying a list of fields in the vertical form with long scrolling can make users confused." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/form-for-submit-data-dont-one.svg",
          "alt": "Dashboard Pattern Don't"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Keep the wizard steps less than 7 steps and display the steps and communicate the progress visually to motivate users." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/form-for-submit-data-do-two.svg",
          "alt": "Dashboard Pattern Do"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use multi-column for heavy data and use too many steps as it will not help users but annoys them instead." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/dashboard/form-for-submit-data-dont-two.svg",
          "alt": "Dashboard Pattern Don't"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      